import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import ContactSection from '../../components/ContactSection'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserBody from '../../components/TeaserBody'
import List from '../../components/List'
import ListItem from '../../components/ListItem'

const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Web"
           description="Wir digitalisieren Ihre Unternehmensprozesse. Vertrauen Sie unseren langjährigen Erfahrungen im Bereich moderner Webtechnologien. Bleiben Sie agil und handlungsfähig."
      />

      <BigTitle
        title="Web" className="mask-black"
        imageRelativePath={'Titelbild_Web.jpg'}
        backgroundColor="#fff"
        textInverted={true}
        logoInverted={true}
        menuInverted={true}
      >
        Klein anfangen. Schneller am Markt sein. Agil und handlungsfähig in der
        Zukunft bleiben.
      </BigTitle>

      <DefaultSection className="pt-5 pt-lg-0">
        <Article>
          <h1>Die Herausforderung</h1>
          <p>
            Effizient gestaltete Prozesse bilden die Grundlage für eine hervorragende Wettbewerbsfähigkeit. Der Einsatz
            moderner Technologien ist hierbei essentiell und fest in den Abläufen der Unternehmen verankert.
            Durch die Digitalisierung und die viel zitierte "Digitale Transformation" steigen die Anforderungen an
            technologie-basierte Prozesse. Im Zentrum stehen Aspekte wie Agilität, Individualisierbarkeit und ein hohes
            Maß
            an zukunftsorientierter Offenheit der Systeme. Wir unterstützen Sie bei der Entwicklung innovativer
            Lösungen.
          </p>
        </Article>
      </DefaultSection>
      <TeaserSection className="bg-level-1">
        <TeaserHeader title="Unser Angebot" hasBody={true}>
          <p>Mit unseren Erfahrungen im Bereich moderner Webtechnologien helfen wir Ihnen bei einer zielgerichteten
            Digitalisierung Ihrer Unternehmensprozesse.</p>
        </TeaserHeader>
        <TeaserBody>
          <List>
            <ListItem title="Analyse">
              <p className="text-black-50">Wir analysieren und dokumentieren mit Ihnen gemeinsam die
                betreffenden Geschäftsprozesse sowie deren Abbildung innerhalb
                Ihrer IT-Systemlandschaft.</p>
            </ListItem>
            <ListItem title="Design">
              <p className="text-black-50">Durch unseren Ansatz des <em>User Centered Design</em> heben wir
                Potentiale und decken nicht ausgesprochene Wünsche und
                Vorschläge Ihrer Nutzergruppe auf.</p>
            </ListItem>
            <ListItem title="Realisierung">
              <p className="text-black-50">Wir realisieren Systeme auf Basis moderner
                Web-Technologien, die auch zukünftigen Anforderungen
                hinsichtlich Nutzerzufriedenheit, Kosteneffizienz und
                Skalierbarkeit gerecht werden.</p>
            </ListItem>
          </List>
        </TeaserBody>
      </TeaserSection>
      <DefaultSection>
        <Article>
          <h1>Das Heft in der Hand behalten</h1>
          <p>
            Technologisch betrachtet gibt es kaum ein Feld, dass schwieriger zu
            durchblicken ist, wie das der Web-Applikationen. Der oftmals fragile
            Lebenszyklus unzähliger Frameworks, Tools und Entwicklungsparadigmen
            macht es tatsächlich schwer, Schritt zu halten.
          </p>
          <blockquote className="text-brand">
            <p>
              Sehr viele Web-Anwendungen sind heute nicht mehr oder nur mit
              unverhältnismäßig hohem Aufwand zu warten bzw. weiterzuentwickeln.
            </p>
          </blockquote>
          <p>
            Das Geflecht an Unwägbarkeiten lässt sich hierbei nicht nur auf den
            frontendbezogenen Teil einer Anwendung abstellen. Architekturansätze
            wie die Etablierung von Microservices müssen, bezogen auf die
            konkrete Sachlage, einer kritischen Bewertung unterzogen werden.
            Welchen Zugewinn bieten solche Ansätze? Welche Alternativen gibt es? Welche Trends sollte man aufgreifen -
            und welche vielleicht
            lieber nicht?
          </p>
          <ul>
            <li>
              Mit Ihnen gemeinsam treffen wir zukunftsorientiere
              Technologieentscheidungen, wählen passende Frameworks aus und
              integrieren diese in Ihren Entwicklungsprozess.
            </li>
            <li>
              Wir helfen Ihnen bei der Beseitigung von Flaschenhälsen im
              Entwicklungsprozess und verkürzen Ihre Release-Zyklen durch den Aufbau
              automatisierter CI/CD-Prozesse erheblich.
            </li>
            <li>
              Wir erarbeiten passgenaue Strategien zur Migration und
              Integration Ihrer Systeme. Durch kontinuierlichen Wissenstransfer
              bei deren Umsetzung machen wir Ihr Team fit für die Zukunft.
            </li>
            <li>
              Durch Security-Audits unterstützen wir Sie bei der Absicherung
              Ihrer Systeme.
            </li>
            <li>
              Wir haben schon viel gesehen. Analysieren Sie gemeinsam mit
              unseren Experten Ihre Bestandsanwendung und lassen Sie sich
              schnell und effizient dabei helfen, herauszufinden,{' '}
              <em>wo die Säge klemmt</em>.
            </li>
          </ul>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-triangle-brand">
        <Article>
          <h1>Den Sprung nach vorn wagen</h1>
          <p>
            Die geforderte Agilität bei der Digitalisierung von
            Geschäftsprozessen stößt nicht selten an Grenzen, deren Ursache{' '}
            <em>technologische Altlasten</em> sind. Oftmals sind es gewohnte
            Vorgehensweisen und Infrastrukturkomponenten, die{' '}
            <em>traditionell gesetzt sind</em>.
          </p>
          <blockquote className="text-brand">
            <p>
              Technologien sind Mittel zum Zweck und dennoch der Dreh- und
              Angelpunkt für innovative Geschäftsmodelle.
            </p>
          </blockquote>
          <p>
            Wer Geschwindigkeit aufnehmen will, um einer neuen Flexibilität Raum
            zu geben, muss sich oftmals von Klassikern wie relationalen
            Datenbanksystemen, klassischen Deployments auf selbstgehosteten
            Applikationsservern oder dem dogmatischen Einsatz einer einzelnen
            Programmiersprache lösen.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <List>
          <ListItem title="NoSQL">
            <p className="text-black-50">
              Durch Rapid-Prototyping zeigen wir schnell und fundiert auf, welche Potenziale
              sich durch den Einsatz moderner Ansätze zur Datenhaltung für Sie heben lassen.</p>
          </ListItem>
          <ListItem title="Cloud">
            <p className="text-black-50">Wir unterstützen Sie beim Aufbau Container-basierter Deployment-Infrastrukturen
              auf der Basis von Docker
              und Kubernetes und machen Ihre Anwendung fit für die Cloud.</p>
          </ListItem>
          <ListItem title="Machine Learning">
            <p className="text-black-50">Nutzen Sie die Daten, die Sie haben! Erkunden Sie ganz neue Möglichkeiten,
              Prozesse und Usability Ihrer
              Anwendungssysteme
              entscheidend zu verbessern.</p>
          </ListItem>
        </List>
      </DefaultSection>

      <ContactSection/>
    </React.Fragment>
  )
}

export default Page
